body {
  overflow-x: hidden;
}

/********      App       ********/

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Disable scroll on body */
.noscroll {
  overflow: hidden;
}

/********      Hero      ********/
.hero {
  background: #2e2e2e;
  height: 100vh;
  color: #fff;
  overflow: hidden;
  position: relative;
}

.hero__chevron {
  position: absolute;
  bottom: 10px;
  left: 50%;
  font-size: 2.4rem;
  transform: translateX(-50%);
  animation: chevron-bounce infinite 1.5s linear;
}

@keyframes chevron-bounce {
  0% {
    transform: translateY(4px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(4px);
  }
}

.hero__title {
  font-size: 3rem;
  font-weight: 300;
}

.hero__contents--green {
  color: #9ef74a;
}

.hero__contents--purple {
  color: #b84af7;
}

.hero__contents--blue {
  color: #4ad5f7;
}

/* Mouse Trail */
.line {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  width: 2px;
  height: 50px;
  background: gray;
  animation: implode 1s ease-in-out;
  animation-fill-mode: both;
  opacity: .5;
}

.line--green {
  background: #9ef74a;
}

.line--purple {
  background: #b84af7;
}

.line--blue {
  background: #4ad5f7;
}

.ball {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: gray;
  animation: implode 1s ease-in-out;
  animation-fill-mode: both;
  opacity: .5;
}

.ball--green {
  background: #9ef74a;
}

.ball--purple {
  background: #b84af7;
}

.ball--blue {
  background: #4ad5f7;
}

.ball--outline-green {
  background: none;
  border: 2px solid #9ef74a;
}

.ball--outline-purple {
  background: none;
  border: 2px solid #b84af7;
}

.ball--outline-blue {
  background: none;
  border: 2px solid #4ad5f7;
}

@keyframes implode {
  100% {
    transform: scale(0);
  }
}

/******** Portfolio Grid ********/
.portfolio {
  background: url("../../images/background.png");
  padding: 5em 0;
  position: relative;
}

/* Portfolio Grid Items */
.port-main__title {
  padding: 0 1em 0.4em;
  border-bottom: 2px solid #4ad5f7;
  display: inline-block;
  font-weight: 300;
  font-size: 2.5em;
}

.port-grid {
  width: 80%;
  margin: 0 auto;
}

.port-item {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 20em;
  height: 18vw;
}

.port-item__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.port-item__overlay {
  position: absolute;
  background: #2e2e2ed2;
  color: #fff;
  top: 150%;
  right: 0;
  bottom: 0;
  left: 0;
  transition: top 250ms ease-in-out;
}

.port-item__dummy-overlay {
  position: absolute;
  background: #fff;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.port-item__dummy-overlay--show {
  opacity: 1;
}

.port-item:hover .port-item__overlay,
.port-item:focus .port-item__overlay {
  top: 0;
}

/* Portfolio Button Links */
.btn-outline-primary {
  background: #2e2e2e;
  border-color: #2e2e2e;
  color: #fff;
  display: inline-block;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
  background: #8d36c0;
  border-color: #8d36c0;
  box-shadow: 0 0 0 0.2rem #8d36c080;
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
  background: #66268b;
  border-color: #66268b;
  box-shadow: 0 0 0 0.2rem #66268b80 !important;
}

.btn-outline-success {
  background: #2e2e2e;
  border-color: #2e2e2e;
  color: #fff;
  display: inline-block;
}

.btn-outline-success:hover,
.btn-outline-success:focus {
  color: #fff;
  background: #70b430;
  border-color: #70b430;
  box-shadow: 0 0 0 0.2rem #70b43080;
}

.btn-outline-success:not(:disabled):not(.disabled):active {
  background: #4e801f;
  border-color: #4e801f;
  box-shadow: 0 0 0 0.2rem #4e801f80 !important;
}

/* Portfolio Content Placeholder */
.port-content__placeholder {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 100;
  top: 0;
  left: 0;
  background: #fff;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.port-content__placeholder.placeholder--trans-in {
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.port-content__placeholder.placeholder--trans-out {
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}

/* Portfolio Content */
.port-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  height: 0;
  /* Avoid second scroll bar in Chrome! */
  visibility: hidden;
  z-index: 400;
  overflow: hidden;
}

.port-content.port-content--show {
  height: 100vh;
  pointer-events: auto;
  visibility: visible;
}

.port-content__title {
  padding: 0 1em 0.4em;
  border-bottom: 2px solid #4ad5f7;
  display: inline-block;
  margin-bottom: 1em;
  font-size: 1.8em;
}

.port-content__img-container {
  width: 100%;
  height: 100%;
  /* max-height: 50vh; */
  margin-bottom: 2em;
}

.port-content__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.port-content__img-container--array {
  /* width: 100%; */
  /* height: 100%; */
  /* max-height: 50vh; */
  display: block;
  margin-bottom: 2em;
}

.port-content__img--array {
  flex-grow: 1;
  flex-shrink: 1;
}

.port-content__role {
  font-weight: 600;
  font-size: 1.4em;
}

.port-content__overview {
  font-size: 1.2em;
}

.port-content__tools {
  width: 80%;
  margin: 0 auto;
  font-size: 1.1em;
}

.port-content__tools-header {
  margin-top: 2em;
  font-size: 1.15em;
}

.port-content__list-tools {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.port-content__list-tools li {
  display: inline-block;
  padding: 0.4em 1em;
  position: relative;
}

.port-content__list-tools li::after {
  position: absolute;
  right: 0;
  top: 10%;
  display: block;
  content: "";
  background: #2e2e2e70;
  width: 2px;
  height: 80%;
}

.port-content__list-tools li:last-of-type::after {
  display: none;
}

.port-content__item {
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  height: 0;
  opacity: 0;
  padding: 60px 18% 60px 18%;
  pointer-events: none;
  /* font-size: 0.85em; */
}

.port-content__item.port-content__item--show {
  overflow-y: scroll;
  min-height: 100vh;
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

/* Portfolio Content Close Button */
.close-button {
  position: fixed;
  border: none;
  background: none;
  margin: 0;
  z-index: 100;
  top: 0;
  right: 0;
  font-size: 18px;
  color: #ddd;
  cursor: pointer;
  pointer-events: none;
  padding: 20px 30px;
  opacity: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.close-button span {
  display: none;
}

.close-button:hover {
  color: #7b7b7b;
}

.close-button--show {
  opacity: 1;
  pointer-events: auto;
}

/********   Info Block   ********/
.info-block {
  background: lightyellow;
}

.info__title {
  border-bottom: 2px solid;
  padding: 0.1em 0.6em;
  font-weight: 300;
  font-size: 2.5em;
}

.about,
.contact {
  /* height: 675px; */
  width: 100%;
  padding: 8%;
  font-size: 1.1em;
}

/********      About     ********/
.about {
  background: #fff;
  /* height: 20em; */
}

.about__title {
  border-color: #b84af7;
  display: inline-block;
  margin-bottom: 0.4em;
}

/********     Contact    ********/
.contact {
  background: #2e2e2e;
  color: #fff;
  /* min-height: 15em; */
}

.contact__title {
  border-color: #9ef74a;
  display: inline-block;
  margin-bottom: 0.4em;
}

.contact__item {
  padding-bottom: 4px;
  display: block;
  width: 100%;
  text-align: left;
  padding: 4px;
  cursor: pointer;
}

.contact__link,
.contact__link:hover {
  text-decoration: none;
  color: #fff;
}

.contact__item:hover {
  color: #9ef74a;
  border-bottom: 2px solid #9ef74a;
  padding-bottom: 2px;
}

.contact__item:hover .contact__link {
  color: #9ef74a;
}

.contact__icon {
  margin-right: 0.6em;
}

@media (min-width: 576px) {
  .hero__title {
    font-size: 4rem;
  }
}

@media (min-width: 768px) {
  .hero__title {
    font-size: 5rem;
  }
}

@media (min-width: 992px) {
  .about,
  .contact {
    height: 300px;
  }
}

@media (min-width: 1300px) {
  .hero__title {
    font-size: 6rem;
  }
  .port-content__img-container--array {
    width: 100%;
    height: 100%;
    max-height: 50vh;
    display: flex;
    margin-bottom: 2em;
  }
  
  .port-content__img-container {
    width: 100%;
    height: 100%;
    max-height: 50vh;
    margin-bottom: 2em;
  }
}
